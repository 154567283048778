import React from 'react';
import { Link } from 'react-router-dom';
import { MdOpenInNew } from 'react-icons/md';
import { Alert } from 'antd';
import { useIntl } from 'react-intl';
import PropTypes from 'prop-types';
import { PrivateComponent } from '../../../../components/HasRights/HasRights';
import { Permissions } from '../../../../const/permissions';
import urlPageDictionaryCreate from '../../../../urls/urlPageDictionaryCreate';
import IntlMessages from '../../../../layout/components/lang/IntlMessages';



const EmptyDictionariesListAlert = ({ isLoading = false, options = [] }) => {
  const intl = useIntl();

  return (
    <div>
      {!isLoading && options.length === 0 && (
        <Alert
          className='hp-mb-8 description-align-end'
          message={intl.formatMessage({ id: 'templates-form-field-empty-dictionaries-list' })}
          type="warning"
          showIcon
          description={
            <PrivateComponent allowedPermissions={[ Permissions.ADMINISTRATION.DICTIONARIES.LIST.CREATE ]}>
              <Link
                to={urlPageDictionaryCreate()}
                target="_blank"
                className='link-contain-icon'
              >
                <IntlMessages id='ui-dictionaries-create' />
                <MdOpenInNew className='hp-ml-8' size={16} />
              </Link>
            </PrivateComponent>
          }
        />
      )}
    </div>
  );
};

EmptyDictionariesListAlert.propTypes = {
  isLoading: PropTypes.bool,
  options: PropTypes.array,
};

export default EmptyDictionariesListAlert;
