const dynamicNews = {
  'dynamic-news-breadcrumbs': 'Новости',
  'dynamic-news-preview-breadcrumbs': 'Детали новости',
  'dynamic-news-create-breadcrumbs': 'Создать новость',
  'dynamic-news-update-breadcrumbs': 'Редактировать новость',
  'dynamic-news-table-delete-btn-confirm-message': 'Вы уверены, что хотите удалить эту новость?',
  'dynamic-news-filter-title': 'Фильтр новостей',
  'dynamic-news-form-id-placeholder': 'ID новости',
  'dynamic-news-form-title-placeholder': 'Название новости',
  'dynamic-news-form-template-key-value-label': 'Значение в ключах',
  'dynamic-news-form-template-key-value-placeholder': 'Значение',
  'dynamic-news-preview-targets-label': 'Назначение',
  'dynamic-news-create-form-title-placeholder': 'Название новости',
  'dynamic-news-create-form-status-placeholder': 'Статус новости',
  'dynamic-news-create-form-targets-min-version-placeholder': 'Минимальная версия приложения',
  'dynamic-news-create-form-targets-max-version-placeholder': 'Максимальная версия приложения',
  'dynamic-news-create-form-upload-description-label': 'Кликните или переместите файл в зону загрузки',
  'dynamic-news-template-validation-error': 'Поле шаблона имеет ошибки в правилах валидации.',
  'dynamic-news-delete-notification': 'Удаление невозможно, данная запись уже опубликована',
  'dynamic-news-fields-priority': 'Сортировка полей',
  'dynamic-news-form-main-attribute': 'Основной атрибут',
};

export default dynamicNews;
