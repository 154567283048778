import React from 'react';
import { Card, Col, Row, Typography } from 'antd';
import { useIntl } from 'react-intl';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import FormImportDictionaries from '../../forms/FormImportDictionaries';
import { useImportDictionariesMutation } from '../../api/dictionariesApiSlice';
import urlPageDictionariesImportSuccess from '../../../../urls/urlPageDictionariesImportSuccess';
import IntlMessages from '../../../../layout/components/lang/IntlMessages';
import BreadCrumbs from '../../../../layout/components/breadcrumbs';
import urlPageDictionaries from '../../../../urls/urlPageDictionaries';
import urlPageFeatures from '../../../../urls/urlPageFeatures';
import getFeatureSourceTranslation from '../../../Featured/utils/getFeatureSourceTranslation';
import handleResponse from '../../../../lib/handleResponse';



const { Title } = Typography;

const PageDictionariesImport = () => {
  const intl = useIntl();
  const navigate = useNavigate();
  const [ searchQueryParams ] = useSearchParams();
  const navigationSource = searchQueryParams.get('source') ?? null;
  const [ importDictionaries, { isLoading: isImportingDictionaries } ] = useImportDictionariesMutation();

  const onImportDictionaries = async (values) => {
    const formData = new FormData();

    formData.append('file', values.file);
    formData.append('source', navigationSource);

    importDictionaries({
      formData,
    })
      .unwrap()
      .then((data) => {
        navigate(urlPageDictionariesImportSuccess({ source: navigationSource }), {
          state: {
            dictionaries: data ?? [],
          },
        });
      })
      .catch((error) => handleResponse('error', intl, 'dictionaries-import-failed', [], error));
  };

  return (
    <>
      <Row
        gutter={[ 32, 32 ]}
        justify="space-between"
        className="hp-print-none hp-mb-32"
        align="middle"
      >
        <BreadCrumbs
          breadCrumbParent={navigationSource ? (
            <Link to={urlPageFeatures({ featureSource: navigationSource })}>
              <IntlMessages id={getFeatureSourceTranslation(navigationSource)} />
            </Link>
          ) : null}
          breadCrumbParent2={<Link to={urlPageDictionaries({ source: navigationSource })}><IntlMessages id='dictionaries-breadcrumbs' /></Link>}
          breadCrumbActive={<IntlMessages id="ui-general-import" />}
        />
      </Row>
      <Row gutter={16}>
        <Col xs={{ span: 24 }} xxl={{ span: 16 }}>
          <Card>
            <Title level={5}><IntlMessages id='dictionaries-import-card-title' /></Title>
            <FormImportDictionaries
              onSubmit={onImportDictionaries}
              isSubmitting={isImportingDictionaries}
            />
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default PageDictionariesImport;
