import React from 'react';
import { Card, Col, Row, Space, Dropdown, Button, Menu, Popconfirm, Typography, Descriptions, Collapse, Tag } from 'antd';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { RiArrowLeftLine } from 'react-icons/ri';
import { PiTrashSimple, PiPencil } from 'react-icons/pi';
import { LuChevronDown } from 'react-icons/lu';
import { FiExternalLink } from 'react-icons/fi';
import dayjs from 'dayjs';
import { useIntl } from 'react-intl';
import BreadCrumbs from '../../../../layout/components/breadcrumbs';
import ActionButton from '../../../../layout/components/action-button';
import IntlMessages from '../../../../layout/components/lang/IntlMessages';
import { Permissions } from '../../../../const/permissions';
import {
  DATE_TIME_FORMAT,
  EMPTY_IMAGE_PLACEHOLDER,
  EMPTY_VALUE_PLACEHOLDER,
} from '../../../../const/system';
import { PrivateComponent } from '../../../../components/HasRights/HasRights';
import urlPagePresets from '../../../../urls/urlPagePresets';
import { useDeletePresetMutation, useGetPresetQuery } from '../../api/presetsApiSlice';
import urlPageTemplateDetails from '../../../../urls/urlPageTemplateDetails';
import urlPagePresetEdit from '../../../../urls/urlPagePresetEdit';
import PresetDataPreview from '../../components/PresetDataPreview';
import Spinner from '../../../../components/Spinner';
import handleResponse from '../../../../lib/handleResponse';



const { Panel } = Collapse;
const { Title } = Typography;

const PagePresetDetails = () => {
  const intl = useIntl();
  const { presetId } = useParams();
  const navigate = useNavigate();
  const { data: preset = {}, isFetching } = useGetPresetQuery({
    id: presetId,
    include: 'template,user',
  });

  const [ deletePreset, { isLoading: isDeletingPreset } ] = useDeletePresetMutation();

  const handleDelete = (presetId) => {
    deletePreset({ id: presetId })
      .unwrap()
      .then(() => handleResponse(
        'success',
        intl,
        'ui-general-deleted-successfully',
        [
          () => navigate(urlPagePresets()),
        ],
      ))
      .catch((error) => handleResponse('error', intl, 'ui-general-delete-failed', [], error));
  };

  const actionItems = (
    <Menu>
      <PrivateComponent allowedPermissions={[ Permissions.CONSTRUCTORS.PRESETS.EDIT ]}>
        <Menu.Item
          key="update"
          icon={<PiPencil size={18} color="orange" />}
          onClick={() => {
            navigate(urlPagePresetEdit({ presetId: preset.id }));
          }}
        >
          <IntlMessages id="ui-general-edit" />
        </Menu.Item>
      </PrivateComponent>
      <PrivateComponent allowedPermissions={[ Permissions.CONSTRUCTORS.PRESETS.DELETE ]}>
        <Popconfirm
          title={<IntlMessages id="ui-general-delete-confirm-message" />}
          placement="top"
          onConfirm={() => {
            handleDelete(preset?.id);
          }}
        >
          <Menu.Item
            key="delete"
            onClick={() => {}}
            icon={<PiTrashSimple size={18} color="red" />}
          >
            <IntlMessages id="ui-general-delete" />
          </Menu.Item>
        </Popconfirm>
      </PrivateComponent>
    </Menu>
  );

  return (
    <>
      <Row
        gutter={[ 32, 32 ]}
        justify="space-between"
        className="hp-print-none hp-mb-32"
        align="middle"
      >
        <Col md={14} span={24}>
          <BreadCrumbs
            breadCrumbParent={
              <Link to={urlPagePresets()}>
                <IntlMessages id="presets-breadcrumbs" />
              </Link>
            }
            breadCrumbActive={preset?.title ?? EMPTY_VALUE_PLACEHOLDER}
          />
        </Col>

        <Col md={10} span={24} className='text-end'>
          <Space size='middle'>
            <PrivateComponent
              allowedPermissions={[
                Permissions.CONSTRUCTORS.PRESETS.EDIT,
                Permissions.CONSTRUCTORS.PRESETS.DELETE,
              ]}
            >
              <Dropdown overlay={actionItems}>
                <Button onClick={(ev) => ev.preventDefault()}>
                  <IntlMessages id='ui-general-action' />
                  <LuChevronDown size={18} className="hp-ml-4" />
                </Button>
              </Dropdown>
            </PrivateComponent>

            <PrivateComponent allowedPermissions={[ Permissions.CONSTRUCTORS.PRESETS.LIST ]}>
              <ActionButton
                title={<IntlMessages id='ui-general-back-to-list' />}
                icon={<RiArrowLeftLine className="hp-mr-8" size={18} />}
                onClick={() => {
                  navigate(urlPagePresets());
                }}
              />
            </PrivateComponent>
          </Space>
        </Col>
      </Row>

      <Spinner spinning={isFetching || isDeletingPreset}>
        <Row gutter={[ 32, 32 ]}>
          <Col span={24}>
            <Card className="hp-border-color-black-40 hp-card-6 preset-details">
              <Row gutter={[ 32, 32 ]}>
                <Col lg={6} sm={8} md={10} xs={24}>
                  <img src={preset.preview ?? EMPTY_IMAGE_PLACEHOLDER} alt="preview preset" />
                </Col>
                <Col lg={18} sm={16} md={14} xs={24}>
                  <Descriptions bordered column={1}>
                    <Descriptions.Item label={<IntlMessages id="ui-general-id" />}>{preset?.id}</Descriptions.Item>
                    <Descriptions.Item label={<IntlMessages id="ui-general-title" />}>{preset?.title}</Descriptions.Item>
                    <Descriptions.Item label={<IntlMessages id="ui-general-template" />}>
                      <Link target='_blank' to={urlPageTemplateDetails({ templateId: preset?.template?.data?.id })}>
                        <div className="hp-d-flex hp-align-items-center">
                          {preset?.template?.data?.title}
                          <FiExternalLink size={14} className="hp-ml-4" />
                        </div>
                      </Link>
                    </Descriptions.Item>
                    <Descriptions.Item label={<IntlMessages id="presets-is-actual" />}>
                      <Tag color={preset?.is_actual ? 'success' : 'error'} >
                        <IntlMessages id={preset.is_actual ? 'ui-general-yes' : 'ui-general-no'} />
                      </Tag>
                    </Descriptions.Item>
                    <Descriptions.Item label={<IntlMessages id="ui-general-created-at" />}>{dayjs(preset?.created_at).format(DATE_TIME_FORMAT)}</Descriptions.Item>
                    <Descriptions.Item label={<IntlMessages id="ui-general-updated-at" />}>{dayjs(preset?.updated_at).format(DATE_TIME_FORMAT)}</Descriptions.Item>
                  </Descriptions>
                </Col>
              </Row>
              <Row gutter={[ 32, 32 ]} className="hp-mt-16">
                <Col xs={24} md={24}>
                  <Collapse defaultActiveKey={[ '1' ]} className="collapse-padding-reset" >
                    <Panel
                      key="1"
                      header={
                        <Title level={5} className='collapse-item-title'>
                          <IntlMessages id="presets-template-details" />
                        </Title>
                      }
                    >
                      <PresetDataPreview preset={preset} />
                    </Panel>
                  </Collapse>
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>
      </Spinner>
    </>
  );
};

export default PagePresetDetails;
