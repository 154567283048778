import React, { useCallback, useEffect, useState } from 'react';
import { Col, Row, Space, Card, Table, Input } from 'antd';
import { Filter } from 'iconsax-react';
import { useSearchParams } from 'react-router-dom';
import isEmpty from 'lodash/isEmpty';
import { Search } from 'react-iconly';
import { useIntl } from 'react-intl';
import { RiAddLine } from 'react-icons/ri';
import IntlMessages from '../../../../layout/components/lang/IntlMessages';
import basePagination from '../../../../const/pagination';
import { DEFAULT_SIDEBAR_WIDTH } from '../../../../const/system';
import { getCategoriesTableColumns } from './PageCategories.const';
import BreadCrumbs from '../../../../layout/components/breadcrumbs';
import ActionButton from '../../../../layout/components/action-button';
import Sidebar from '../../../../components/sidebar/Sidebar';
import useToggle from '../../../../app/hooks/useToggle';
import useQueryParams from '../../../../app/hooks/useQueryParams';
import getQueryParams from '../../../../lib/getQueryParams';
import makeTableLoadingObject from '../../../../lib/makeTableLoadingObject';
import handleResponse from '../../../../lib/handleResponse';
import applyFilter from '../../../../lib/applyFilter';
import resetFilter from '../../../../lib/resetFilter';
import TotalLabel from '../../../../components/TotalLabel';
import FormCategoriesFilter from '../../forms/FormCategoriesFilter';
import FormCreateEditCategories from '../../forms/FormCreateEditCategories';
import {
  useCreateCategoryMutation,
  useDeleteCategoryMutation,
  useGetCategoriesQuery,
  useUpdateCategoryMutation,
} from '../../api/categoriesApiSlice';



const categoryInitialStateValues = { status: false };

const PageCategories = () => {
  const intl = useIntl();
  const [ searchQueryParams, setSearchParams ] = useSearchParams();
  const [ searchField, setSearchField ] = useState('');
  const [ initFilterValues, setInitFilterValues ] = useState({});
  const [ categoryInitialValues, setCategoryInitialValues ] = useState(categoryInitialStateValues);
  const [ categoryInitialErrors, setCategoryInitialErrors ] = useState({});
  const [ currentCategoryId, setCurrentCategoryId ] = useState(null);
  const [ isEdit, setIsEdit ] = useState(false);
  const [ filterOpen, toggleFilterSidebar ] = useToggle();
  const [ filterOpenCreateEditSidebar, toggleCreateEditSidebar ] = useToggle();

  const searchFields = [ 'name' ];
  const searchFilterFields = [ 'client_id', 'name', 'status', 'created_from', 'created_to' ];

  const {
    pagination,
    searchParams,
    search,
    setSearchTerm,
    handleChangeTableParams,
  } = useQueryParams({ searchFields, searchFilterFields, isFilter: true });

  const { data: {
    data: categories = [],
    pagination: dataPagination = {},
  } = { data: [], pagination: {} }, isFetching } = useGetCategoriesQuery({
    queryParams: `${searchParams.toString()}&include=dictionary`,
  });

  const [ deleteCategory, { isLoading: isDeletingCategory } ] = useDeleteCategoryMutation();
  const [ createCategory, { isLoading: isCreatingCategory } ] = useCreateCategoryMutation();
  const [ updateCategory, { isLoading: isUpdatingCategory } ] = useUpdateCategoryMutation();

  useEffect(() => {
    const queryParams = getQueryParams(window.location.search);

    if (queryParams.search) {
      const initValues = {};

      queryParams.search.split(';').forEach((item) => {
        const [ key, value ] = item.split(':');

        if (value !== '') {
          initValues[key] = value;
        }

        if (searchFields.includes(key)) {
          setSearchField(value);
          setSearchTerm(value);
        }
      });

      setInitFilterValues(initValues);
    }
  }, []);

  useEffect(() => {
    if (!searchParams.toString()) {
      setInitFilterValues({
        reset: true, // ugly hack
      });
    }

    localStorage.setItem('indexSearchParams', searchParams.toString());
  }, [ searchParams ]);

  const handleSearch = useCallback((event) => {
    setSearchField(event.target.value);
    setInitFilterValues({
      reset: true,
    });
    setSearchTerm(event.target.value);
  }, []);

  const handleOpenSidebarOnCreate = () => {
    setIsEdit(false);
    toggleCreateEditSidebar();
    setCategoryInitialValues(categoryInitialStateValues);
    setCategoryInitialErrors({});
    setCurrentCategoryId(null);
  };

  const handleOpenSidebarOnEdit = (id) => {
    const editedCategory = categories.find((category) => category.id === id);

    setCategoryInitialValues({
      client_id: editedCategory.client_id,
      name: editedCategory.name,
      dictionary_id: editedCategory?.dictionary?.data?.id,
      status: editedCategory.status,
      description: editedCategory.description,
      options: editedCategory.options,
    });

    toggleCreateEditSidebar();
    setCategoryInitialErrors({});
    setIsEdit(true);
    setCurrentCategoryId(id);
  };

  const handleSubmit = (values) => {
    const action = isEdit
      ? updateCategory({ id: currentCategoryId, payload: values })
      : createCategory({ payload: values });

    action
      .unwrap()
      .then(() => {
        toggleCreateEditSidebar();
        setCategoryInitialValues(categoryInitialStateValues);
      })
      .then(() => handleResponse(
        'success',
        intl,
        isEdit
          ? 'categories-update-successfully'
          : 'categories-create-successfully',
      ))
      .catch((error) => {
        handleResponse(
          'error',
          intl,
          isEdit
            ? 'categories-update-failed'
            : 'categories-create-failed',
          [
            () => setCategoryInitialErrors(error?.data?.errors),
          ],
          error,
        );
      });
  };

  const handleDelete = (id) => {
    deleteCategory(id)
      .unwrap()
      .then(() => handleResponse('success', intl, 'ui-general-deleted-successfully'))
      .catch((error) => handleResponse('error', intl, 'ui-general-delete-failed', [], error));
  };

  const isLoading = isFetching || isDeletingCategory || isCreatingCategory || isUpdatingCategory;
  const currentDictionaryId = categories.find((category) => category.id === currentCategoryId)
    ?.dictionary?.data?.id;

  return (
    <>
      <Row
        gutter={[ 32, 32 ]}
        justify="space-between"
        className="hp-print-none hp-mb-32"
        align="middle"
      >
        <BreadCrumbs breadCrumbActive={<IntlMessages id='categories-breadcrumbs' />} />

        <Col>
          <Space>
            <TotalLabel total={dataPagination.total ?? 0} />
            <Col>
              <Input
                allowClear={!isEmpty(search)}
                placeholder={intl.formatMessage({ id: 'ui-general-search' })}
                prefix={<Search set="curved" size={16} className="hp-text-color-black-80" />}
                value={searchField}
                onChange={handleSearch}
              />
            </Col>
            <ActionButton
              onClick={handleOpenSidebarOnCreate}
              icon={<RiAddLine className="btn-icon-mr-1" />}
              title={<IntlMessages id='ui-general-create' />}
            />
            <ActionButton
              title=""
              icon={<Filter size={18} />}
              onClick={toggleFilterSidebar}
            />
          </Space>
        </Col>

        <Sidebar
          visible={filterOpen}
          toggleSidebar={toggleFilterSidebar}
          width={DEFAULT_SIDEBAR_WIDTH}
        >
          <FormCategoriesFilter
            isSubmitting={isLoading}
            initialValues={initFilterValues}
            onCancel={() => {
              resetFilter({
                searchQueryParams,
                setSearchParams,
                setInitFilterValues,
              });
            }}
            onSubmit={(values) => {
              applyFilter({
                values,
                searchQueryParams,
                searchFilterFields,
                setSearchParams,
                setSearchField,
                toggleFilterSidebar,
              });
            }}
          />
        </Sidebar>

        <Sidebar
          visible={filterOpenCreateEditSidebar}
          toggleSidebar={toggleCreateEditSidebar}
          width={DEFAULT_SIDEBAR_WIDTH}
          title={<IntlMessages id={isEdit ? 'ui-general-edit' : 'ui-general-create'} />}
        >
          <FormCreateEditCategories
            initialValues={categoryInitialValues}
            onSubmit={handleSubmit}
            isSubmitting={false}
            initialErrors={categoryInitialErrors}
            handleResetErrors={() => {
              setCategoryInitialErrors({});
            }}
            currentDictionaryId={currentDictionaryId}
          />
        </Sidebar>
      </Row>

      <Row gutter={[ 32, 32 ]}>
        <Col span={24}>
          <Card className="hp-border-color-black-40 hp-card-6">
            <Table
              sticky
              loading={makeTableLoadingObject(isLoading)}
              rowKey="id"
              columns={getCategoriesTableColumns(handleOpenSidebarOnEdit, handleDelete)}
              dataSource={categories}
              onChange={handleChangeTableParams}
              pagination={{
                current: pagination.page,
                pageSize: pagination.limit,
                total: dataPagination?.total,
                ...basePagination,
              }}
            />
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default PageCategories;
