import React from 'react';
import { Spin } from 'antd';
import IntlMessages from "../../layout/components/lang/IntlMessages";



const AppLoader = () => {
  return (
    <div className='app-loader'>
      <Spin tip={<IntlMessages id='ui-general-loading'/>} />
    </div>
  );
};


export default AppLoader;
