import { Popconfirm, Row, Tooltip } from 'antd';
import { RiErrorWarningLine } from 'react-icons/ri';
import { Delete } from 'react-iconly';
import React from 'react';
import { PiPencil } from 'react-icons/pi';
import PropTypes from 'prop-types';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { HasRights } from '../../../../components/HasRights/HasRights';
import { Permissions } from '../../../../const/permissions';
import IntlMessages from '../../../../layout/components/lang/IntlMessages';
import CopyToClipboard from '../../../../components/CopyToClipboard';
import cleanupHtmlTags from '../../../../lib/cleanupHtmlTags';
import { EMPTY_VALUE_PLACEHOLDER } from '../../../../const/system';



const CopiedMessageRow = ({ text = '' }) => {
  if (!text) {
    return EMPTY_VALUE_PLACEHOLDER;
  }

  return (
    <div className='centered-item align-items-centered'>
      <span style={{ color: '#aaa', paddingRight: '.5em' }}>
        <IntlMessages id='ui-general-title' />
        :
      </span>
      <div className='push-icon__message' dangerouslySetInnerHTML={{ __html: text }} />
      <CopyToClipboard value={cleanupHtmlTags(text)} />
    </div>
  );
};

CopiedMessageRow.propTypes = {
  text: PropTypes.string,
};

export const getPushIconsTableColumns = (preview, handleEdit, handeDelete) => [
  {
    title: <IntlMessages id="ui-general-id" />,
    dataIndex: 'id',
    align: 'center',
    width: '120px',
    sorter: true,
  },
  {
    title: <IntlMessages id="ui-general-content" />,
    dataIndex: 'icon',
    align: 'center',
    width: '270px',
    render: (value) => (
      <LazyLoadImage
        className='hp-cursor-pointer'
        style={{
          height: '150px',
          width: '150px',
        }}
        alt='preview'
        src={value}
        onClick={() => {
          preview(value);
        }}
      />),
  },
  {
    title: <IntlMessages id="push-icons-table-message-column" />,
    align: 'center',
    dataIndex: 'message',
    render: (value) => {
      return (
        <>
          <CopiedMessageRow text={value?.title} />
          <CopiedMessageRow text={value?.text} />
        </>
      );
    },
  },
  {
    title: <IntlMessages id="push-icons-table-link-column" />,
    dataIndex: 'link',
    width: '160px',
    align: 'center',
    render: (value) => (
      <span>
        <a aria-label="push icons link" href={value} target="_blank" rel="noreferrer">
          <IntlMessages id="push-icons-table-link-column" />
        </a>
        <CopyToClipboard value={value} />
      </span>),
  },
  {
    title: <IntlMessages id="ui-general-action" />,
    key: 'action',
    align: 'center',
    width: '120px',
    render: (item) => {
      return (
        <Row className="da-h-100" align="middle" justify="center">
          {HasRights([ Permissions.OTHER.PUSH_ICONS.LIST.EDIT ]) && !item.is_secure && (
            <Tooltip placement="top" title={<IntlMessages id="ui-general-edit" />}>
              <div className="hp-text-right">
                <PiPencil
                  onClick={() => handleEdit(item)}
                  size={20}
                  className="hp-cursor-pointer hp-transition hp-hover-text-color-warning-1 hp-text-color-black-80 hp-m-4"
                />
              </div>
            </Tooltip>
          )}
          {HasRights([ Permissions.OTHER.PUSH_ICONS.LIST.DELETE ]) && (
            <Popconfirm
              title={<IntlMessages id="ui-general-delete-confirm-message" />}
              placement="top"
              onConfirm={() => {
                handeDelete(item.id);
              }}
              okText={<IntlMessages id="ui-general-yes" />}
              cancelText={<IntlMessages id="ui-general-no" />}
              icon={<RiErrorWarningLine className="remix-icon hp-text-color-primary-1" />}
              okButtonProps={{ danger: true }}
            >
              <Tooltip placement="top" title={<IntlMessages id="ui-general-delete" />}>
                <div className="hp-text-right">
                  <Delete
                    size={20}
                    className="hp-cursor-pointer hp-transition hp-hover-text-color-danger-1 hp-text-color-black-80 hp-m-4"
                  />
                </div>
              </Tooltip>
            </Popconfirm>
          )}
        </Row>
      );
    },
  },
];
