const structures = {
  'structures-breadcrumbs-item': 'Редактирование структуры',
  'structures-details-title': 'Редактирование структуры',
  'structures-details-preview': 'Предпросмотр структуры',
  'structures-preview-warning': 'Данные структуры недоступны!',
  'structures-source-field': 'Источник',
  'structures-version-field': 'Версия',
  'form-structures-description-placeholder': 'Опишите структуру',
  'form-structures-add-field': 'Добавить поле',
  'validation-structures-min-fields': 'Необходимо добавить хотя бы одно поле',
  'validation-structures-fields-validation-min-number': 'Поле должно быть числом',
  'validation-structures-fields-validation-max-number': 'Поле должно быть числом',
  'structures-fields-delete-confirm-message': 'Вы действительно хотите удалить поле?',
  'structures-source-old news': 'Старые новости',
  'structures-source-captions': 'Подписи',
  'structures-source-news': 'Новости',
  'structures-source-daily': 'Ежедневные',
  'structures-source-categories': 'Категории',
  'structures-source-editors-choice': 'Выбор редакторов',
  'structures-source-external-promo': 'Внешние промо',
  'structures-source-btn-featured': 'Кнопка фичеров',
  'structures-source-popups': 'Диалоги',
  'structures-source-popup': 'Диалоги',
  'structures-source-btn-cta': 'Cta кнопка',
  'structures-source-btn-yes-no': 'Да/Нет кнопка',
};

export default structures;
