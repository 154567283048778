import { Popconfirm, Row, Tooltip } from 'antd';
import { Link } from 'react-router-dom';
import { Eye } from 'iconsax-react';
import { PiPencil } from 'react-icons/pi';
import { RiErrorWarningLine } from 'react-icons/ri';
import { Delete } from 'react-iconly';
import React from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { FiExternalLink } from 'react-icons/fi';
import { LiaSyncSolid } from 'react-icons/lia';
import { Permissions } from '../../../../const/permissions';
import getFormattedDate from '../../../../lib/getFormattedDate';
import { HasRights, PrivateComponent } from '../../../../components/HasRights/HasRights';
import { DATE_TIME_FORMAT, EMPTY_IMAGE_PLACEHOLDER } from '../../../../const/system';
import IntlMessages from '../../../../layout/components/lang/IntlMessages';
import urlPageEditPreset from '../../../../urls/urlPageEditPreset';
import urlPageTemplateDetails from '../../../../urls/urlPageTemplateDetails';
import urlPagePresetDetails from '../../../../urls/urlPagePresetDetails';
import CollapsibleText from '../../../../components/CollapsibleText';



export const getPresetsTableColumns = (handleDelete) => [
  {
    title: <IntlMessages id="ui-general-id" />,
    dataIndex: 'id',
    align: 'center',
    sorter: true,
  },
  {
    title: <IntlMessages id="ui-general-preview" />,
    dataIndex: 'preview',
    align: 'center',
    render: (value) => (
      <LazyLoadImage
        width={50}
        effect='blur'
        alt="preset preview"
        src={value ?? EMPTY_IMAGE_PLACEHOLDER}
      />
    ),
  },
  {
    title: <IntlMessages id="ui-general-title" />,
    dataIndex: 'title',
    align: 'center',
    sorter: true,
    render: (value) => <CollapsibleText text={value} />,
  },
  {
    title: <IntlMessages id="ui-general-template" />,
    align: 'center',
    render: (item) => {
      return (
        <Link style={{ wordBreak: 'break-all' }} to={urlPageTemplateDetails({ templateId: item?.template?.data?.id })} target="_blank">
          <div className="hp-align-items-center">
            <Tooltip placement="top" title={<IntlMessages id="presets-is-not-actual" />}>
              {!item.is_actual && <LiaSyncSolid size={14} className="hp-mr-4 hp-text-color-danger-2" />}
            </Tooltip>
            {item?.template?.data?.title}
            <FiExternalLink size={14} className="hp-ml-4" />
          </div>
        </Link>
      );
    },
  },
  {
    title: <IntlMessages id="presets-table-author-column" />,
    dataIndex: 'user',
    align: 'center',
    render: (value) => value?.data?.name,
  },
  {
    title: <IntlMessages id="ui-general-created-at" />,
    dataIndex: 'created_at',
    align: 'center',
    sorter: true,
    render: (value) => getFormattedDate(value, DATE_TIME_FORMAT),
  },
  {
    title: <IntlMessages id="ui-general-updated-at" />,
    dataIndex: 'updated_at',
    align: 'center',
    sorter: true,
    render: (value) => getFormattedDate(value, DATE_TIME_FORMAT),
  },
  ...(HasRights([ Permissions.CONSTRUCTORS.PRESETS.FIND, Permissions.CONSTRUCTORS.PRESETS.EDIT, Permissions.CONSTRUCTORS.PRESETS.DELETE ]) ?
    [ {
      title: <IntlMessages id="ui-general-action" />,
      key: 'action',
      align: 'center',
      render: (item) => {
        return (
          <Row className="da-h-100" align="middle" justify="center">
            <PrivateComponent allowedPermissions={[ Permissions.CONSTRUCTORS.PRESETS.FIND ]}>
              <Tooltip placement="top" title={<IntlMessages id="ui-general-view" />}>
                <div className="hp-text-right">
                  <Link to={urlPagePresetDetails({ presetId: item.id })}>
                    <Eye
                      size={20}
                      className="hp-cursor-pointer hp-transition hp-hover-text-color-primary-1 hp-text-color-black-80 hp-m-4"
                    />
                  </Link>
                </div>
              </Tooltip>
            </PrivateComponent>
            <PrivateComponent allowedPermissions={[ Permissions.CONSTRUCTORS.PRESETS.EDIT ]}>
              <Tooltip placement="top" title={<IntlMessages id="ui-general-edit" />}>
                <div className="hp-text-right">
                  <Link to={urlPageEditPreset({ presetId: item.id })}>
                    <PiPencil
                      size={20}
                      className="hp-cursor-pointer hp-transition hp-hover-text-color-warning-1 hp-text-color-black-80 hp-m-4"
                    />
                  </Link>
                </div>
              </Tooltip>
            </PrivateComponent>
            <PrivateComponent allowedPermissions={[ Permissions.CONSTRUCTORS.PRESETS.DELETE ]}>
              <Popconfirm
                title={<IntlMessages id="ui-general-delete-confirm-message" />}
                placement="top"
                onConfirm={() => {
                  handleDelete(item.id);
                }}
                okText={<IntlMessages id="ui-general-yes" />}
                cancelText={<IntlMessages id="ui-general-no" />}
                icon={<RiErrorWarningLine className="remix-icon hp-text-color-primary-1" />}
                okButtonProps={{ danger: true }}
              >
                <Tooltip placement="top" title={<IntlMessages id="ui-general-delete" />}>
                  <div className="hp-text-right">
                    <Delete
                      size={20}
                      className="hp-cursor-pointer hp-transition hp-hover-text-color-danger-1 hp-text-color-black-80 hp-m-4"
                    />
                  </div>
                </Tooltip>
              </Popconfirm>
            </PrivateComponent>
          </Row>
        );
      },
    } ] : []
  ),
];
