import React, { useState } from 'react';
import { Card, Col, Modal, Row, Table } from 'antd';
import { RiCloseFill } from 'react-icons/ri';
import basePagination from '../../../../const/pagination';
import IntlMessages from '../../../../layout/components/lang/IntlMessages';
import { getListTableColumns } from './PageStructures.const';
import { useGetStructuresQuery } from '../../api/structuresApiSlice';
import useQueryParams from '../../../../app/hooks/useQueryParams';
import useToggle from '../../../../app/hooks/useToggle';
import makeTableLoadingObject from '../../../../lib/makeTableLoadingObject';
import BreadCrumbs from '../../../../layout/components/breadcrumbs';
import PreviewStructure from '../../components/PreviewStructure';
import { useGetLanguagesQuery } from '../../../Language/api/languagesApiSlice';



const PageStructures = () => {
  const [ openModal, toggleModal ] = useToggle();
  const [ previewStructure, setPreviewStructure ] = useState({
    source: '',
    language_ids: [],
    version: 0,
    description: '',
    fields: {},
  });

  const {
    pagination,
    searchParams,
    handleChangeTableParams,
  } = useQueryParams();

  const { data = { data: [], pagination: {} }, isLoading } = useGetStructuresQuery({
    queryParams: searchParams.toString(),
  });
  const { data: languages } = useGetLanguagesQuery({
    queryParams: 'limit=0',
  });

  const handleView = async (structure) => {
    await setPreviewStructure(structure);
    toggleModal();
  };

  return (
    <>
      <Modal
        title={<IntlMessages id="structures-details-preview" />}
        width={700}
        centered
        destroyOnClose
        visible={openModal}
        onCancel={toggleModal}
        footer={null}
        closeIcon={
          <RiCloseFill className="remix-icon text-color-black-100" size={24} />
        }
      >
        <PreviewStructure
          structure={previewStructure}
          languages={languages?.languages ?? []}
        />
      </Modal>

      <Row
        gutter={[ 32, 32 ]}
        justify="space-between"
        className="hp-print-none hp-mb-32"
      >
        <BreadCrumbs breadCrumbActive={<IntlMessages id='ui-general-structures' />} />
      </Row>

      <Row gutter={32}>
        <Col span={24}>
          <Card className="hp-border-color-black-40 hp-card-6">
            <Table
              sticky
              loading={makeTableLoadingObject(isLoading)}
              rowKey="id"
              columns={getListTableColumns(handleView)}
              dataSource={data.data}
              onChange={handleChangeTableParams}
              pagination={{
                ...basePagination,
                current: pagination.page,
                pageSize: pagination.limit,
                total: data.pagination?.total,
              }}
            />
          </Card>
        </Col>
      </Row>
    </>
  );
};


export default PageStructures;
