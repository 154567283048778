import { BsCopy } from 'react-icons/bs';
import React from 'react';
import { notification, Tooltip } from 'antd';
import { RiCheckboxCircleFill, RiCloseFill } from 'react-icons/ri';
import { useIntl } from 'react-intl';
import useCopyToClipboard from '../../app/hooks/useCopyToClipboard';
import IntlMessages from '../../layout/components/lang/IntlMessages';
import appColors from "../../const/colors";



const CopyToClipboard = ({ value }) => {
  const [ _, copy ] = useCopyToClipboard();
  const intl = useIntl();

  return (
    <Tooltip placement="top" title={<IntlMessages id="ui-general-copy" />}>
      {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
      <span
        className="hp-p-4 hp-cursor-pointer" onClick={() => {
          copy(value)
            .then(() => {
              notification.open({
                duration: 0.65,
                top: 65,
                message: intl.formatMessage({ id: 'ui-general-copied' }),
                icon: <RiCheckboxCircleFill style={{ color: appColors.success }} />,
                closeIcon: (
                  <RiCloseFill className="remix-icon da-text-color-black-80" size={24} />
                ),
              });
            });
        }}
      >
        <BsCopy />
      </span>
    </Tooltip>
  );
};

export default CopyToClipboard;
