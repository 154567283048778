import React from 'react';
import { Spin } from 'antd';
import { useIntl } from "react-intl";



const Spinner = ({
  spinning = false,
  tip,
  style = {},
  children,
}) => {
  const intl = useIntl();

  return (
      <Spin
        style={style}
        spinning={spinning}
        tip={tip ?? intl.formatMessage({ id: 'ui-general-loading' })}
      >
        {children}
      </Spin>
  );
};


export default Spinner;
