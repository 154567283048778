/* eslint-disable react/no-unstable-nested-components */
import React, { useEffect, useRef } from 'react';
import { Formik } from 'formik';
import PropTypes from 'prop-types';
import { Form, FormItem, Input, Select, SubmitButton, Switch } from 'formik-antd';
import { useIntl } from 'react-intl';
import { Button, Col, DatePicker, Row, Tooltip } from 'antd';
import isEmpty from 'lodash/isEmpty';
import { InfoCircleOutlined, QuestionCircleOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import moment from 'moment';
import IntlMessages from '../../../../layout/components/lang/IntlMessages';
import {
  AUTOFOCUS_INPUT_SELECTOR,
  TEMPLATE_FIELD_SELECT_DOCS_EXTERNAL_LINK,
  TEMPLATE_SUBTYPE_OPTIONS,
  TEMPLATE_TYPE_OPTIONS,
  TEMPLATE_TYPES_DOCS_EXTERNAL_LINK,
  TEMPLATE_TYPES_WITH_DEFAULT_VALUE,
  TEMPLATE_TYPES_WITH_VALIDATION,
  TEMPLATE_VALIDATION_RULES_DOCS_EXTERNAL_LINK,
  TYPE_ARRAY,
  TYPE_BOOLEAN,
  TYPE_COLOR,
  TYPE_DATE,
  TYPE_DICTIONARY,
  TYPE_DOUBLE,
  TYPE_IMAGE,
  TYPE_INTEGER,
  TYPE_OBJECT,
  TYPE_SELECT,
  TYPE_STRING,
} from '../../const/templates';
import DefaultValueDropdownRender from '../../components/DefaultValueDropdownRender';
import ValidationRulesDropdownRender from '../../components/ValidationRulesDropdownRender';
import EmptyDictionariesListAlert from '../../../Dictionary/components/EmptyDictionariesListAlert';
import { validationSchema } from './FormCreateTemplateFieldValidationSchema.const';
import { uniqueKeyValidation } from '../../utils/uniqueKeyValidation';
import { buildTemplateField } from '../../utils/buildTemplateField';
import { getFieldKeysOnCurrentTreeLevel } from '../../utils/getFieldKeysOnCurrentTreeLevel';
import Colorpicker from '../../../../components/Colorpicker';
import useDatepickerHandler from '../../../../app/hooks/useDatepickerHandler';
import { DATE_FORMAT } from '../../../../const/system';



const SELECT_DROPDOWN_VALIDATION_SELECTOR = '.hc-select-dropdown--validation';
const SELECT_DROPDOWN_DEFAULT_VALUE_SELECTOR = '.hc-select-dropdown--default-value';

const { TextArea } = Input;

const FormCreateEditTemplateField = ({
  isEdit,
  initialValues,
  onSubmit,
  currentHashKey,
  tree,
  structures,
  isStructuresLoading,
}) => {
  const intl = useIntl();
  const { onBlur } = useDatepickerHandler();
  const keysOnCurrentTreeLevel = useRef([]);
  const wasCheckboxTouched = useRef(isEdit);
  const oldKey = useRef(null);

  const structureOptions = structures?.data ? structures.data.map((structure) => {
    return {
      value: structure.id,
      label: `${intl.formatMessage({ id: `structures-source-${structure.source}` })} (v.${structure.version})`,
    };
  }) : [];

  const handleChangeType = async (value, setFieldValue, setFieldError, setFieldTouched) => {
    if (value === TYPE_BOOLEAN) {
      setFieldValue('default_value', false, false);
    } else if (value === TYPE_ARRAY) {
      setFieldValue('default_value', [], false);
    } else {
      setFieldValue('default_value', '', false);
    }

    if (value === TYPE_ARRAY) {
      await setFieldValue('subtype', TYPE_STRING, true);
    } else {
      await setFieldValue('subtype', null, false);
    }

    if (value === TYPE_OBJECT) {
      await setFieldValue('use_in_preset', false, false);
      await setFieldValue('title_en', null, false);
      await setFieldValue('title_ru', null, false);
      await setFieldValue('hint_en', null, false);
      await setFieldValue('hint_en', null, false);
    }

    setFieldError('default_value', '');
    setFieldValue('value', null);
    setFieldValue('validation', [], false);

    await setFieldValue('type', value, true);
    await setFieldTouched('type', true, true);
  };

  useEffect(() => {
    keysOnCurrentTreeLevel.current = getFieldKeysOnCurrentTreeLevel(tree, isEdit, currentHashKey);
  });

  useEffect(() => {
    oldKey.current = isEdit ? initialValues?.key : null;
  });

  return (
    <Formik
      enableReinitialize
      validationSchema={validationSchema}
      initialValues={initialValues}
      initialErrors={{}}
      onSubmit={(values, { resetForm }) => {
        onSubmit({ key: values.key, node: buildTemplateField(values, wasCheckboxTouched.current) }, isEdit, currentHashKey);

        wasCheckboxTouched.current = false;
        resetForm();
      }}
    >
      {({ isValid, dirty, handleReset, values, setFieldValue, setFieldError, setFieldTouched, errors }) => {
        const displayDefaultValueField = !isEmpty(values.type)
          ? (TEMPLATE_TYPES_WITH_DEFAULT_VALUE.includes(values.type) && ![ TYPE_OBJECT, TYPE_IMAGE ].includes(values.subtype))
          : false;

        return (
          <Form layout="vertical" className='w-full'>
            <FormItem
              className='ant-form-item-col stretched'
              name="key"
              label={
                <Row className='w-full' justify="space-between" align="middle">
                  <IntlMessages id="templates-create-form-field-key" />
                  <Tooltip placement="topRight" title={<IntlMessages id="templates-create-form-field-key-help" />}>
                    <InfoCircleOutlined />
                  </Tooltip>
                </Row>
              }
              required
              validate={(value) => uniqueKeyValidation(
                keysOnCurrentTreeLevel.current,
                value,
                oldKey.current,
                isEdit,
              )}
            >
              <Input
                name='key'
                placeholder={intl.formatMessage({ id: 'templates-create-form-field-key-placeholder' })}
              />
            </FormItem>

            <FormItem
              label={intl.formatMessage({ id: 'templates-create-form-field-is-required' })}
              name='is_required'
            >
              <Switch
                name="is_required"
                checkedChildren={intl.formatMessage({ id: 'ui-general-required' })}
                unCheckedChildren={intl.formatMessage({ id: 'ui-general-optional' })}
              />
            </FormItem>

            <FormItem
              className='ant-form-item-col stretched'
              name="type"
              label={
                <Row className='w-full' justify="space-between" align="middle">
                  <IntlMessages id="ui-general-type" />
                  <Link to={TEMPLATE_TYPES_DOCS_EXTERNAL_LINK} target="_blank">
                    <QuestionCircleOutlined />
                  </Link>
                </Row>
              }
              required
            >
              <Select
                disabled={isEdit && [ TYPE_OBJECT ].includes(initialValues.type)}
                name="type"
                options={TEMPLATE_TYPE_OPTIONS}
                placeholder={intl.formatMessage({ id: 'ui-general-choose-type' })}
                onChange={(value) => handleChangeType(value, setFieldValue, setFieldError, setFieldTouched)}
              />
            </FormItem>

            {!isEmpty(values.type) && values.type !== TYPE_OBJECT && (
              <>
                <FormItem
                  className='ant-form-item-col stretched'
                  name="title_en"
                  required
                  label={
                    <Row className='w-full' justify="space-between" align="middle">
                      <IntlMessages id="templates-create-form-field-title-en" />
                      <Tooltip placement="topRight" title={<IntlMessages id="templates-create-form-field-hint-help" />}>
                        <InfoCircleOutlined />
                      </Tooltip>
                    </Row>
                  }
                >
                  <Input
                    name='title_en'
                    placeholder='My title'
                  />
                </FormItem>

                <FormItem
                  className='ant-form-item-col stretched'
                  name="title_ru"
                  required
                  label={
                    <Row className='w-full' justify="space-between" align="middle">
                      <IntlMessages id="templates-create-form-field-title-ru" />
                      <Tooltip placement="topRight" title={<IntlMessages id="templates-create-form-field-hint-help" />}>
                        <InfoCircleOutlined />
                      </Tooltip>
                    </Row>
                  }
                >
                  <Input
                    name='title_ru'
                    placeholder='Мой заголовок'
                  />
                </FormItem>

                <FormItem
                  className='ant-form-item-col stretched'
                  name="hint_en"
                  label={
                    <Row className='w-full' justify="space-between" align="middle">
                      <IntlMessages id="templates-create-form-field-hint-en" />
                      <Tooltip placement="topRight" title={<IntlMessages id="templates-create-form-field-hint-help" />}>
                        <InfoCircleOutlined />
                      </Tooltip>
                    </Row>
                  }
                >
                  <Input
                    name='hint_en'
                    placeholder='My hint'
                  />
                </FormItem>

                <FormItem
                  className='ant-form-item-col stretched'
                  name="hint_ru"
                  label={
                    <Row className='w-full' justify="space-between" align="middle">
                      <IntlMessages id="templates-create-form-field-hint-ru" />
                      <Tooltip placement="topRight" title={<IntlMessages id="templates-create-form-field-hint-help" />}>
                        <InfoCircleOutlined />
                      </Tooltip>
                    </Row>
                  }
                >
                  <Input
                    name='hint_ru'
                    placeholder='Моя подсказка'
                  />
                </FormItem>

                <FormItem
                  className='ant-form-item-col stretched'
                  name='use_in_preset'
                  label={
                    <Row className='w-full' justify="space-between" align="middle">
                      <IntlMessages id="templates-create-form-field-use-in-preset" />
                      <Tooltip placement="topRight" title={<IntlMessages id="templates-create-form-field-use-in-preset-tooltip" />}>
                        <InfoCircleOutlined />
                      </Tooltip>
                    </Row>
                  }
                >
                  <Switch
                    name="use_in_preset"
                    checkedChildren={intl.formatMessage({ id: 'ui-general-on' })}
                    unCheckedChildren={intl.formatMessage({ id: 'ui-general-off' })}
                  />
                </FormItem>
              </>
            )}

            {values.type === TYPE_ARRAY && (
              <FormItem
                className='ant-form-item-col stretched'
                name="subtype"
                label={
                  <Row className='w-full' justify="space-between" align="middle">
                    <IntlMessages id="templates-create-form-field-subtype" />
                    <Link to={TEMPLATE_TYPES_DOCS_EXTERNAL_LINK} target="_blank">
                      <QuestionCircleOutlined />
                    </Link>
                  </Row>
                }
              >
                <Select
                  name="subtype"
                  options={TEMPLATE_SUBTYPE_OPTIONS}
                  defaultValue={TYPE_STRING}
                  onChange={(value) => {
                    setFieldValue('subtype', value, false);
                    setFieldValue('validation', [], false);
                  }}
                  placeholder={<IntlMessages id="templates-create-form-field-subtype-placeholder" />}
                />
              </FormItem>
            )}

            {TEMPLATE_TYPES_WITH_VALIDATION.includes(values.type) && (
              <FormItem
                className='ant-form-item-col stretched'
                name='validation'
                label={
                  <Row className='w-full' justify="space-between" align="middle">
                    <IntlMessages id="templates-create-form-field-validation" />
                    <Link to={TEMPLATE_VALIDATION_RULES_DOCS_EXTERNAL_LINK} target="_blank">
                      <QuestionCircleOutlined />
                    </Link>
                  </Row>
                }
              >
                <Select
                  mode="multiple"
                  allowClear
                  onFocus={() => {
                    document
                      .querySelector(`${SELECT_DROPDOWN_VALIDATION_SELECTOR} ${AUTOFOCUS_INPUT_SELECTOR}`)
                      .focus();
                  }}
                  showSearch={false}
                  name='validation'
                  value={values.validation}
                  placeholder={<IntlMessages id="templates-create-form-field-validation-placeholder" />}
                  dropdownRender={() => (<ValidationRulesDropdownRender
                    values={values}
                    setFieldValue={setFieldValue}
                    errors={errors}
                  />)}
                />
              </FormItem>
            )}

            {displayDefaultValueField && (
              <FormItem
                className="ant-form-item-col stretched"
                name="default_value"
                label={(
                  <Row className='w-full' justify="space-between" align="middle">
                    <IntlMessages id="templates-create-form-field-default-value" />
                    <Tooltip placement="topRight" title={<IntlMessages id="templates-create-form-field-default-value-help" />}>
                      <InfoCircleOutlined />
                    </Tooltip>
                  </Row>
                )}
              >
                {[ TYPE_STRING, TYPE_INTEGER, TYPE_DOUBLE ].includes(values.type) ? (
                  <Input
                    name='default_value'
                    type={values.type === TYPE_STRING ? 'text' : 'number'}
                    placeholder={intl.formatMessage({ id: 'templates-create-form-field-default-value-placeholder' })}
                  />
                ) : values.type === TYPE_COLOR ? (
                  <Colorpicker
                    name='default_value'
                    defaultValue={values.default_value}
                    onChange={async (value) => {
                      await setFieldValue('default_value', value);
                    }}
                  />
                ) : values.type === TYPE_DATE ? (
                  <DatePicker
                    name='default_value'
                    format={DATE_FORMAT}
                    showToday
                    value={values?.default_value ? moment(values.default_value) : null}
                    onChange={async (date, dateString) => {
                      await setFieldValue('default_value', dateString);
                    }}
                    onBlur={async ({ target }) => {
                      await onBlur('default_value', target.value, setFieldValue);
                    }}
                  />
                ) : values.type === TYPE_BOOLEAN ? (
                  <Switch
                    name="default_value"
                    onChange={(checked) => {
                      setFieldValue('default_value', checked, true);
                      wasCheckboxTouched.current = true;
                    }}
                    checkedChildren={intl.formatMessage({ id: 'ui-general-on' })}
                    unCheckedChildren={intl.formatMessage({ id: 'ui-general-off' })}
                  />
                ) : (
                  <Select
                    mode="multiple"
                    allowClear
                    showSearch={false}
                    name='default_value'
                    value={values.default_value}
                    placeholder={<IntlMessages id="templates-create-form-field-default-value-placeholder" />}
                    dropdownRender={() => (<DefaultValueDropdownRender
                      values={values}
                      setFieldValue={setFieldValue}
                      errors={errors}
                    />)}
                    onFocus={() => {
                      document
                        .querySelector(`${SELECT_DROPDOWN_DEFAULT_VALUE_SELECTOR} ${AUTOFOCUS_INPUT_SELECTOR}`)
                        .focus();
                    }}
                  />
                )}
              </FormItem>
            )}

            {values.type === TYPE_DICTIONARY && (
              <>
                <EmptyDictionariesListAlert
                  isLoading={isStructuresLoading}
                  options={structureOptions}
                />

                <FormItem
                  className='ant-form-item-col'
                  name="value"
                  label={<IntlMessages id="templates-create-form-field-dictionary-structure" />}
                  required
                >
                  <Select
                    name="value"
                    options={structureOptions}
                    loading={isStructuresLoading}
                    placeholder={<IntlMessages id="templates-create-form-field-select-placeholder" />}
                  />
                </FormItem>
              </>
            )}

            {values.type === TYPE_SELECT && (
              <FormItem
                className='ant-form-item-col stretched'
                name="value"
                label={
                  <Row className='w-full' justify="space-between" align="middle">
                    <IntlMessages id="templates-create-form-field-select" />
                    <Link to={TEMPLATE_FIELD_SELECT_DOCS_EXTERNAL_LINK} target="_blank">
                      <QuestionCircleOutlined />
                    </Link>
                  </Row>
                }
                required
              >
                <TextArea
                  name='value'
                  rows={4}
                />
              </FormItem>
            )}

            <Row gutter={[ 16, 16 ]} justify='end'>
              <Col>
                <Button onClick={handleReset}>
                  <IntlMessages id='ui-general-reset' />
                </Button>
              </Col>
              <Col>
                <SubmitButton disabled={!isValid || !dirty}>
                  <IntlMessages id='ui-general-save' />
                </SubmitButton>
              </Col>
            </Row>
          </Form>
        );
      }}
    </Formik>
  );
};

FormCreateEditTemplateField.propTypes = {
  isEdit: PropTypes.bool,
  onSubmit: PropTypes.func.isRequired,
  initialValues: PropTypes.shape({
    key: PropTypes.string,
    type: PropTypes.string,
    hint_en: PropTypes.string,
    hint_ru: PropTypes.string,
    is_required: PropTypes.bool,
    use_in_preset: PropTypes.bool,
    subtype: PropTypes.string,
    validation: PropTypes.array,
    default_value: PropTypes.oneOfType([ PropTypes.bool, PropTypes.array, PropTypes.string, PropTypes.number ]),
    value: PropTypes.oneOfType([ PropTypes.string, PropTypes.number ]),
  }).isRequired,
  currentHashKey: PropTypes.string,
  tree: PropTypes.shape({}),
  structures: PropTypes.object,
  isStructuresLoading: PropTypes.bool,
};

export default FormCreateEditTemplateField;
