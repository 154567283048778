import {Collapse, Descriptions, Select, Tag, Typography} from 'antd';
import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import { Link, useLocation } from "react-router-dom";
import { useIntl } from "react-intl";
import isEmpty from 'lodash/isEmpty';
import appColors from "../../const/colors";
import IntlMessages from '../../layout/components/lang/IntlMessages';
import { EMPTY_VALUE_PLACEHOLDER } from '../../const/system';
import { TYPE_OBJECT } from '../../domains/Constructors/const/templates';
import { countries as countryOptions } from "../../const/countries";
import urlPageTranslationDetails from "../../urls/urlPageTranslationDetails";
import { MdOpenInNew } from "react-icons/md";
import getTemplateTitle from '../../lib/getTemplateTitle';
import PreviewDictionaryTranslation from '../../domains/Dictionary/components/PreviewDictionaryTranslation/PreviewDictionaryTranslation';
import capitalize from "lodash/capitalize";
import useCheckMobileScreen from "../../app/hooks/useCheckMobileScreen";



const { Title } = Typography;
const { Panel } = Collapse;


const mkDescriptionItem = (value) => {
  return (
    <Descriptions.Item
      key={value.id}
      label={getTemplateTitle(value)}
      className='width-20-vw'
    >
      {value.type === TYPE_OBJECT ? (
        value?.children.map((childObjItem) => childObjItem?.title ?? EMPTY_VALUE_PLACEHOLDER)
      ) : value?.title ?? EMPTY_VALUE_PLACEHOLDER}
    </Descriptions.Item>
  )
};


const mkTemplateDescriptionCard = ({ value, isMobile = false }) => {
  return (
    <Descriptions
      key={value.id}
      bordered
      layout={isMobile ? 'vertical' : 'horizontal'}
      column={1}
    >
      {value.hasOwnProperty('children') ? (
        value?.children.map((item) => mkDescriptionItem(item))
      ) :  mkDescriptionItem(value)}
    </Descriptions>
  )
};

const TemplateCollapsibleCards = ({
  dictionary = [],
  languages = [],
  data = {},
  targetData = {},
}) => {
  const { pathname } = useLocation();
  const intl = useIntl();
  const isMobile = useCheckMobileScreen(600);
  const currentFlow = pathname.split('/').slice(1)[0];
  const localStorageKey = 'templateCollapseState';

  const countries = countryOptions.map((option) => ({ ...option, disabled: true }));

  const getInitialState = () => {
    const storedState = localStorage.getItem(localStorageKey);
    const parsedState = storedState ? JSON.parse(storedState) : {};

    return parsedState[currentFlow] ?? ['dictionary', 'template'];
  };

  const [ activeKeys, setActiveKeys ] = useState(getInitialState);

  useEffect(() => {
    const storedState = localStorage.getItem(localStorageKey);
    const parsedState = storedState ? JSON.parse(storedState) : {};
    parsedState[currentFlow] = activeKeys;

    localStorage.setItem(localStorageKey, JSON.stringify(parsedState));
  }, [ activeKeys, currentFlow ]);

  const handleCollapseChange = (key) => {
    const newActiveKeys = activeKeys.includes(key)
      ? activeKeys.filter(activeKey => activeKey !== key)
      : [...activeKeys, key];

    setActiveKeys(newActiveKeys);
  };

  return (
    <div className="hp-mb-24">
      {!isEmpty(dictionary) && (
        <Collapse
          collapsible
          className='hp-mb-24'
          key='dictionary'
          activeKey={activeKeys}
          onChange={() => handleCollapseChange('dictionary')}
        >
          <Panel
            key='dictionary'
            header={
              <Title className='collapse-item-title' level={5}>
                <Link
                  to={urlPageTranslationDetails({ id: dictionary.id })}
                  target="_blank"
                >
                  <span style={{color: appColors.darkGray}}>
                    {`${intl.formatMessage({ id: 'dynamic-news-dictionary-name' })}: ${dictionary?.name ?? EMPTY_VALUE_PLACEHOLDER}`}
                  </span>
                  <MdOpenInNew className='hp-ml-8' />
                </Link>

              </Title>
            }
          >
            <div className="hp-ml-16">
              <PreviewDictionaryTranslation
                allowEdit={false}
                dictionaryDetails={dictionary}
                languages={languages}
              />
            </div>
          </Panel>
        </Collapse>
      )}

      {data?.templateValues.length > 0 && (
      <Collapse
        collapsible
        className="hp-mb-24 collapse-padding-reset"
        key='template'
        activeKey={activeKeys}
        onChange={() => handleCollapseChange('template')}
      >
        <Panel
          key='template'
          header={
            <Title className='collapse-item-title' level={5}>
              <IntlMessages id='ui-general-template-data' />
            </Title>
          }
        >
          {data?.templateValues.map((value) => mkTemplateDescriptionCard({ value, isMobile }))}
        </Panel>
      </Collapse>
      )}

      {data?.additionalValues.length > 0 && (
        <Collapse
          collapsible
          className="hp-mb-24 collapse-padding-reset"
          key='additional'
          activeKey={activeKeys}
          onChange={() => handleCollapseChange('additional')}
        >
          <Panel
            key='additional'
            header={
              <Title className='collapse-item-title' level={5}>
                <IntlMessages id='templates-collapse-additional-title' />
              </Title>
            }
          >
            {data?.additionalValues.map((value) => mkTemplateDescriptionCard({ value, isMobile }))}
          </Panel>
        </Collapse>
      )}

      {!isEmpty(targetData) && (
        <Collapse
          collapsible
          key='target'
          activeKey={activeKeys}
          onChange={() => handleCollapseChange('target')}
          className='hp-mb-24 collapse-padding-reset'
        >
          <Panel
            key="target"
            header={
              <Title className='collapse-item-title' level={5}>
                <IntlMessages id='dynamic-news-preview-targets-label' />
              </Title>
            }
          >
            <Descriptions
              bordered
              layout={isMobile ? 'vertical' : 'horizontal'}
              column={1}
            >
              <Descriptions.Item style={{ width: '50%' }} label={<IntlMessages id="ui-general-min-version" />}>
                {targetData?.min_version || EMPTY_VALUE_PLACEHOLDER}
              </Descriptions.Item>
              <Descriptions.Item label={<IntlMessages id="ui-general-max-version" />}>
                {targetData?.max_version || EMPTY_VALUE_PLACEHOLDER}
              </Descriptions.Item>
              <Descriptions.Item label={<IntlMessages id="ui-general-platform" />}>
                {targetData?.platform.length ? targetData?.platform?.map((item, index)=> {
                  return (
                    <Tag key={index} color="purple">{capitalize(item)}</Tag>
                  )
                }) : EMPTY_VALUE_PLACEHOLDER}
              </Descriptions.Item>
              <Descriptions.Item label={<IntlMessages id="ui-general-countries" />}>
                <Select
                  className="single-line-select w-full"
                  mode="multiple"
                  name='countries'
                  value={targetData?.countries}
                  options={countries}
                />
              </Descriptions.Item>
            </Descriptions>
          </Panel>
        </Collapse>
      )}
    </div>
  );
};

TemplateCollapsibleCards.propTypes = {
  dictionary: PropTypes.array,
  languages: PropTypes.array,
  data: PropTypes.object,
  targetData: PropTypes.object,
};

export default TemplateCollapsibleCards;
