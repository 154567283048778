import { Popconfirm, Row, Tag, Tooltip } from 'antd';
import { Eye } from 'iconsax-react';
import { PiPencil } from 'react-icons/pi';
import { RiErrorWarningLine } from 'react-icons/ri';
import { Delete } from 'react-iconly';
import React from 'react';
import { BsCopy } from 'react-icons/bs';
import { Link } from 'react-router-dom';
import { DATE_TIME_FORMAT, EMPTY_VALUE_PLACEHOLDER } from '../../../../const/system';
import { Permissions } from '../../../../const/permissions';
import getFormattedDate from '../../../../lib/getFormattedDate';
import CollapsibleText from '../../../../components/CollapsibleText';
import { PrivateComponent } from '../../../../components/HasRights/HasRights';
import IntlMessages from '../../../../layout/components/lang/IntlMessages';
import urlPageEditTemplate from '../../../../urls/urlPageEditTemplate';
import urlPageTemplateDetails from '../../../../urls/urlPageTemplateDetails';



export const getTemplatesTableColumns = (handleClone, handleDelete) => [
  {
    title: <IntlMessages id="ui-general-id" />,
    dataIndex: 'id',
    align: 'center',
    sorter: true,
  },
  {
    title: <IntlMessages id="ui-general-status" />,
    dataIndex: 'is_active',
    align: 'center',
    sorter: true,
    render: (value) => (<Tag color={value ? 'success' : 'warning'}><IntlMessages id={value ? 'ui-general-on' : 'ui-general-off'} /></Tag>),
  },
  {
    title: <IntlMessages id="ui-general-title" />,
    dataIndex: 'title',
    align: 'center',
    sorter: true,
    render: (value) => <CollapsibleText text={value} />,
  },
  {
    title: <IntlMessages id="ui-general-content-type" />,
    dataIndex: 'content_type',
    align: 'center',
    sorter: true,
    render: (value) => value?.data?.display_name ?? EMPTY_VALUE_PLACEHOLDER,
  },
  {
    title: <IntlMessages id="ui-general-created-at" />,
    dataIndex: 'created_at',
    align: 'center',
    sorter: true,
    render: (value) => getFormattedDate(value, DATE_TIME_FORMAT),
  },
  {
    title: <IntlMessages id="ui-general-action" />,
    key: 'action',
    align: 'center',
    render: (item) => {
      return (
        <Row className="da-h-100" align="middle" justify="center">
          <PrivateComponent allowedPermissions={[ Permissions.CONSTRUCTORS.TEMPLATES.FIND ]}>
            <Tooltip placement="top" title={<IntlMessages id="ui-general-view" />}>
              <div className="hp-text-right">
                <Link to={urlPageTemplateDetails({ templateId: item.id })}>
                  <Eye
                    size={20}
                    className="hp-cursor-pointer hp-transition hp-hover-text-color-primary-1 hp-text-color-black-80 hp-m-4"
                  />
                </Link>
              </div>
            </Tooltip>
          </PrivateComponent>
          <PrivateComponent allowedPermissions={[ Permissions.CONSTRUCTORS.TEMPLATES.EDIT ]}>
            <Tooltip placement="top" title={<IntlMessages id="ui-general-edit" />}>
              <div className="hp-text-right">
                <Link to={urlPageEditTemplate({ templateId: item.id })}>
                  <PiPencil
                    size={20}
                    className="hp-cursor-pointer hp-transition hp-hover-text-color-warning-1 hp-text-color-black-80 hp-m-4"
                  />
                </Link>
              </div>
            </Tooltip>
          </PrivateComponent>
          <PrivateComponent allowedPermissions={[ Permissions.CONSTRUCTORS.TEMPLATES.CLONE ]}>
            <Tooltip placement="top" title={<IntlMessages id="ui-general-clone" />}>
              <div className="hp-text-right">
                <BsCopy
                  onClick={() => {
                    handleClone(item.id);
                  }}
                  size={18}
                  className="hp-cursor-pointer hp-transition hp-hover-text-color-success-1 hp-text-color-black-80 hp-m-4"
                />
              </div>
            </Tooltip>
          </PrivateComponent>
          {item.is_deletable && (
            <PrivateComponent allowedPermissions={[ Permissions.CONSTRUCTORS.TEMPLATES.DELETE ]}>
              <Popconfirm
                title={<IntlMessages id="ui-general-delete-confirm-message" />}
                placement="top"
                onConfirm={() => {
                  handleDelete(item.id);
                }}
                okText={<IntlMessages id="ui-general-yes" />}
                cancelText={<IntlMessages id="ui-general-no" />}
                icon={<RiErrorWarningLine className="remix-icon hp-text-color-primary-1" />}
                okButtonProps={{ danger: true }}
              >
                <Tooltip placement="top" title={<IntlMessages id="ui-general-delete" />}>
                  <div className="hp-text-right">
                    <Delete
                      size={20}
                      className="hp-cursor-pointer hp-transition hp-hover-text-color-danger-1 hp-text-color-black-80 hp-m-4"
                    />
                  </div>
                </Tooltip>
              </Popconfirm>
            </PrivateComponent>
          )}
        </Row>
      );
    },
  },
];
