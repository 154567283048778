import React from 'react';
import { Form, FormItem, Select, SubmitButton } from 'formik-antd';
import { Formik } from 'formik';
import PropTypes from 'prop-types';
import { Button, Col, Row } from 'antd';
import IntlMessages from '../../../../layout/components/lang/IntlMessages';
import { HasRights } from '../../../../components/HasRights/HasRights';
import { Permissions } from '../../../../const/permissions';
import makeSelectFilterOption from '../../../../lib/makeSelectFilterOption';



const { Option } = Select;


const FormEditPermissions = ({
  onSubmit,
  initialValues,
  isSubmitting,
  permissionsOptions,
  formErrors,
  isDisabled,
  handleSetFormEdit,
}) => {
  return (
    <Formik
      enableReinitialize
      isSubmitting={isSubmitting}
      initialValues={initialValues}
      initialErrors={formErrors}
      onSubmit={onSubmit}
    >
      {(props) => {
        // eslint-disable-next-line react/prop-types
        const { values, isValid, resetForm, dirty } = props;

        return (
          <Form layout="vertical" className='w-full'>
            <FormItem
              label={<IntlMessages id='ui-general-permissions' />}
              name='permissions_ids'
              required
            >
              <Select
                mode="multiple"
                allowClear
                showSearch
                disabled={isDisabled}
                name="permissions_ids"
                className='w-full'
                placeholder={<IntlMessages id='form-user-role-permissions-placeholder' />}
                value={values.permissions_ids}
                optionFilterProp="children"
                filterOption={(input, option) => makeSelectFilterOption(option.children, input)}
                filterSort={(optionA, optionB) => optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())}
              >
                {permissionsOptions.map((permission) => (
                  <Option key={permission.id} value={permission.id}>
                    {permission.display_name || permission.description}
                  </Option>
                ))}
              </Select>
            </FormItem>

            {HasRights([
              Permissions.ADMINISTRATION.ROLES.ROLE_DETAILS.EDIT_PERMISSIONS,
              Permissions.ADMINISTRATION.PERMISSIONS.VIEW_LIST,
            ], true) && (
              <Row gutter={[ 16, 16 ]} justify='center'>
                <Col>
                  <Button
                    type='secondary'
                    onClick={() => {
                      resetForm();
                      handleSetFormEdit(!isDisabled);
                    }}
                  >
                    <IntlMessages id={isDisabled ? 'ui-general-edit' : 'ui-general-cancel'} />
                  </Button>
                </Col>

                {!isDisabled && (
                  <Col>
                    <SubmitButton
                      loading={isSubmitting}
                      disabled={!isValid || !dirty}
                    >
                      <IntlMessages id='ui-general-submit' />
                    </SubmitButton>
                  </Col>
                )}
              </Row>
            )}
          </Form>
        );
      }}
    </Formik>
  );
};

FormEditPermissions.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  handleSetFormEdit: PropTypes.func.isRequired,
  initialValues: PropTypes.shape({}).isRequired,
  values: PropTypes.shape({
    permissions_ids: PropTypes.array,
  }),
  permissionsOptions: PropTypes.array,
  formErrors: PropTypes.object,
  isSubmitting: PropTypes.bool.isRequired,
  isDisabled: PropTypes.bool.isRequired,
};

FormEditPermissions.defaultProps = {
  values: {
    permissions_ids: [],
  },
  permissionsOptions: [],
  formErrors: {},
};

export default FormEditPermissions;
