import React, { useCallback, useEffect, useState } from 'react';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import { Button, Card, Col, Input, Row, Space, Table, Typography } from 'antd';
import { RiAddLine } from 'react-icons/ri';
import { Search } from 'react-iconly';
import { Filter } from 'iconsax-react';
import { useIntl } from 'react-intl';
import isEmpty from 'lodash/isEmpty';
import IntlMessages from '../../../../layout/components/lang/IntlMessages';
import basePagination from '../../../../const/pagination';
import urlPageCreateTemplate from '../../../../urls/urlPageCreateTemplate';
import urlPageEditTemplate from '../../../../urls/urlPageEditTemplate';
import ActionButton from '../../../../layout/components/action-button';
import BreadCrumbs from '../../../../layout/components/breadcrumbs';
import Sidebar from '../../../../components/sidebar/Sidebar';
import useToggle from '../../../../app/hooks/useToggle';
import useQueryParams from '../../../../app/hooks/useQueryParams';
import FormFilterTemplates from '../../forms/FormFilterTemplates';
import makeTableLoadingObject from '../../../../lib/makeTableLoadingObject';
import getQueryParams from '../../../../lib/getQueryParams';
import applyFilter from '../../../../lib/applyFilter';
import resetFilter from '../../../../lib/resetFilter';
import handleResponse from '../../../../lib/handleResponse';
import { getTemplatesTableColumns } from './PageTemplates.const';
import {
  useCloneTemplateMutation,
  useDeleteTemplateMutation,
  useGetTemplateContentTypesQuery,
  useGetTemplatesQuery,
} from '../../api/templatesApiSlice';



const { Title } = Typography;

const PageTemplates = () => {
  const intl = useIntl();
  const navigate = useNavigate();
  const [ searchQueryParams, setSearchParams ] = useSearchParams();
  const [ isVisibleFilterSidebar, toggleFilterSidebar ] = useToggle();
  const [ initFilterValues, setInitFilterValues ] = useState({});
  const [ searchField, setSearchField ] = useState('');

  const searchFields = [ 'title' ];
  const searchFilterFields = [ 'id', 'title', 'status', 'created_from', 'created_to', 'content_type' ];

  const {
    pagination,
    search,
    setSearchTerm,
    searchParams,
    handleChangeTableParams,
  } = useQueryParams({ searchFields, searchFilterFields, isFilter: true });

  const {
    data: contentTypes = { data: [] },
    isFetchingContentTypes,
  } = useGetTemplateContentTypesQuery();

  const {
    data = { data: [], pagination: {} },
    isFetching,
  } = useGetTemplatesQuery({
    queryParams: `${searchParams.toString()}`,
  });

  const [ deleteTemplate, { isLoading: isDeletingTemplate } ] = useDeleteTemplateMutation();
  const [ cloneTemplate, { isLoading: isCloningTemplate } ] = useCloneTemplateMutation();

  useEffect(() => {
    setSearchField(search);
  }, []);

  useEffect(() => {
    const queryParams = getQueryParams(window.location.search);

    if (queryParams.search) {
      const initValues = {};

      queryParams.search.split(';').forEach((item) => {
        const [ key, value ] = item.split(':');

        if (value !== '') {
          initValues[key] = value;
        }
      });

      setInitFilterValues(initValues);
    }
  }, []);

  useEffect(() => {
    if (!searchParams.toString()) {
      setInitFilterValues({
        reset: true, // ugly hack
      });
    }

    localStorage.setItem('searchParams', searchParams.toString());
  }, [ searchParams ]);

  const handleSearch = useCallback((event) => {
    setSearchField(event.target.value);
    setSearchTerm(event.target.value);
  }, []);

  const handleDeleteTemplate = (id) => {
    deleteTemplate(id)
      .unwrap()
      .then(() => handleResponse('success', intl, 'ui-general-deleted-successfully'))
      .catch((error) => handleResponse('error', intl, 'ui-general-delete-failed', [], error));
  };

  const handleCloneTemplate = (id) => {
    cloneTemplate(id)
      .unwrap()
      .then((res) => handleResponse(
        'success',
        intl,
        'templates-clone-successfully',
        [
          () => navigate(urlPageEditTemplate({ templateId: res.data.id })),
        ],
      ))
      .catch((error) => handleResponse('error', intl, 'templates-clone-failed', [], error));
  };

  const isLoading = isFetching || isDeletingTemplate || isCloningTemplate || isFetchingContentTypes;

  return (
    <>
      <Row
        gutter={[ 32, 32 ]}
        justify="space-between"
        className="hp-print-none hp-mb-32"
        align="middle"
      >
        <BreadCrumbs breadCrumbActive={<IntlMessages id='templates-breadcrumbs' />} />

        <Col md={12} span={24}>
          <Row
            gutter={[ 16, 16 ]}
            justify="end"
            align="middle"
          >
            <Col md={12} span={24}>
              <Input
                allowClear={!isEmpty(search)}
                placeholder={intl.formatMessage({ id: 'ui-general-search' })}
                prefix={<Search set="curved" size={16} className="hp-text-color-black-80" />}
                value={searchField}
                onChange={handleSearch}
              />
            </Col>
            <Col>
              <Space>
                <Link to={urlPageCreateTemplate()}>
                  <Button
                    type="primary"
                    className="hp-mr-sm-8"
                    ghost="true"
                    icon={<RiAddLine className="btn-icon-mr-1" />}
                  >
                    <IntlMessages id='ui-general-create' />
                  </Button>
                </Link>
              </Space>
            </Col>
            <Col>
              <Space>
                <ActionButton
                  title=""
                  icon={<Filter size={18} />}
                  onClick={toggleFilterSidebar}
                />
              </Space>
            </Col>
          </Row>
        </Col>

        <Sidebar
          title={<Title level={5}>{intl.formatMessage({ id: 'templates-filter-title' })}</Title>}
          visible={isVisibleFilterSidebar}
          toggleSidebar={toggleFilterSidebar}
        >
          <FormFilterTemplates
            initialValues={initFilterValues}
            contentTypes={contentTypes ?? []}
            isSubmitting={isFetching}
            onCancel={() => {
              resetFilter({
                searchQueryParams,
                setSearchParams,
                setInitFilterValues,
                callbacks: [ () => setSearchField('') ],
              });
            }}
            onSubmit={(values) => {
              applyFilter({
                values,
                searchQueryParams,
                searchFilterFields,
                setSearchParams,
                setSearchField,
                toggleFilterSidebar,
              });
            }}
          />
        </Sidebar>
      </Row>

      <Row gutter={[ 32, 32 ]}>
        <Col span={24}>
          <Card className="hp-border-color-black-40 hp-card-6">
            <Table
              sticky
              loading={makeTableLoadingObject(isLoading)}
              rowKey="id"
              columns={getTemplatesTableColumns(handleCloneTemplate, handleDeleteTemplate)}
              dataSource={data?.data}
              onChange={handleChangeTableParams}
              pagination={{
                current: pagination.page,
                pageSize: pagination.limit,
                total: data.pagination?.total,
                ...basePagination,
              }}
            />
          </Card>
        </Col>
      </Row>
    </>
  );
};


export default PageTemplates;

